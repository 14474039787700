






































































































































































































































































































































import { Mixins, Component } from 'vue-property-decorator'
import { View } from '@/app_code/services/meta/view'
import Topic from '../components/Topic.vue'
import SideBySide from '../components/layout/SideBySide.vue'
import ImageCard from '../components/cards/ImageCard.vue'
import LinkButton from '../components/ui/LinkButton.vue'
import { API, ServiceManager } from '@/app_code/services'
import ServiceType from '@/app_code/services/ServiceTypes'
import NumberIncrement from '@/components/NumberIncrement.vue'

@Component({
  components: {
    Topic,
    SideBySide,
    ImageCard,
    LinkButton,
    NumberIncrement
  }
})
export default class About extends Mixins(View) {
    private unitCount: number | string = 'a number of'
  private trainingPackagesCount: number | string = 'many'

  private api = ServiceManager.Require<API>(ServiceType.API)

  mounted (): void {
    this.getCategories()
    this.getUnitCount()
  }

  getCategories (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.UnitsAPI.GetCategories(true)
        .then(data => {
          this.trainingPackagesCount = data?.length ?? 0
          resolve(true)
        })
        .catch(reject)
    })
  }

  getUnitCount (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.UnitsAPI.GetUnits(1, '', '00000000-0000-0000-000000000000')
        .then(data => {
          this.unitCount = data?.totalItems ?? 0
          resolve(true)
        })
        .catch(reject)
    })
  }
}
